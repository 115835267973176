.footer {
  display: block;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8ecfd;
  background: rgb(232 244 255 / 59%) !important;
  margin-top: auto;
  padding: 10px 20px;
}

.footer-logo {
  width: 40px;
  height: 40px;
}

.footer-txt {
  font-size: 18px;
  padding-top: 7px;
  padding-left: 10px;
}

.footer-iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
}

.footer-icons {
  margin-right: 25px;
  color: #636e72;
  cursor: pointer;
}

.footer-icons:hover {
  color: #0984e3;
}

@media (max-width: 993px) {
  .footer {
    position: relative;
    bottom: 75px;
  }
}

@media (max-width: 600px) {
  .footer {
    display: none;
  }
}
