* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.edit-profile {
  width: 340px;
  background: #fff;
  box-shadow: 0 0 1px rgb(20 21 26 / 10%), 0 3px 6px rgb(71 77 87 / 4%),
    0 1px 2px rgb(20 21 26 / 4%);
  border-radius: 34px;
  margin: 25px auto;
  padding: 20px 0px;
}
