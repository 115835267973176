.tbls {
  filter: drop-shadow(rgba(25, 19, 38, 0.15) 0px 1px 4px);
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  margin: 16px 0px;
}

.tbldiv {
  overflow: visible;
}

table {
  border-spacing: 0px;
}

.tbl {
  border-collapse: collapse;
  font-size: 14px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.trdata {
  cursor: pointer;
  border-bottom: 2px solid rgb(231, 227, 235);
}

.tbldata {
  padding: 24px 8px 24px 0px;
  display: flex;
  width: 100%;
  align-items: center;
}

.tbldd {
  min-height: 24px;
  display: flex;
  align-items: center;
}

.tblimg {
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.tbi {
  width: 24px;
  height: 24px;
}

.tblim {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  padding-top: 0%;
  margin-right: 8px;
}

.tbltxtn {
  color: RGB(39, 117, 219);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.tbdy tr td {
  font-size: 16px;
  vertical-align: middle;
}

.tblsub {
  font-size: 12px;
  color: skyblue;
  text-align: left;
}

.tblnum {
  color: rgb(189, 194, 196);
  display: flex;
  align-items: center;
}

.tblnump {
  display: flex;
  align-items: center;
  color: RGB(39, 117, 219);
}

.ktblnumper {
  min-width: 60px;
  text-align: left;
}

.tblsub {
  font-size: 12px;
  color: skyblue;
  text-align: left;
}

.tblx {
  display: flex;
  align-items: center;
}

.tblxx {
  color: RGB(39, 117, 219);
  width: 36px;
  text-align: right;
  margin-right: 14px;
}

.tblxxx {
  display: inline-block;
}

.tblsvg {
  align-self: center;
  fill: skyblue;
  flex-shrink: 0;
}

.tblssvvg {
  transform: rotate(0deg);
  height: 20px;
}

.tblssvg {
  align-self: center;
  fill: rgb(31, 199, 212);
  flex-shrink: 0;
}

.tblcd {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 8px;
  color: rgb(31, 199, 212);
}

.liq,
.mult {
  display: block;
}

@media screen and (min-width: 1080px) {
  .tbldata {
    padding-right: 32px;
  }
}

@media screen and (min-width: 968px) {
  .tblxx {
    text-align: left;
    margin-right: 0px;
  }
}

@media screen and (min-width: 576px) {
  .tblimg {
    padding-left: 32px;
  }

  .tbi {
    width: 40px;
    height: 40px;
  }

  .tblcd {
    padding-right: 0px;
  }
}

@media screen and (max-width: 480px) {
  .liq,
  .mult {
    display: none;
  }

  .tblimg {
    padding-left: 8px;
  }
}
