@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
.footer {
  display: block;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8ecfd;
  background: rgb(232 244 255 / 59%) !important;
  margin-top: auto;
  padding: 10px 20px;
}

.footer-logo {
  width: 40px;
  height: 40px;
}

.footer-txt {
  font-size: 18px;
  padding-top: 7px;
  padding-left: 10px;
}

.footer-iconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
}

.footer-icons {
  margin-right: 25px;
  color: #636e72;
  cursor: pointer;
}

.footer-icons:hover {
  color: #0984e3;
}

@media (max-width: 993px) {
  .footer {
    position: relative;
    bottom: 75px;
  }
}

@media (max-width: 600px) {
  .footer {
    display: none;
  }
}

.tbls {
  -webkit-filter: drop-shadow(rgba(25, 19, 38, 0.15) 0px 1px 4px);
          filter: drop-shadow(rgba(25, 19, 38, 0.15) 0px 1px 4px);
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  margin: 16px 0px;
}

.tbldiv {
  overflow: visible;
}

table {
  border-spacing: 0px;
}

.tbl {
  border-collapse: collapse;
  font-size: 14px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.trdata {
  cursor: pointer;
  border-bottom: 2px solid rgb(231, 227, 235);
}

.tbldata {
  padding: 24px 8px 24px 0px;
  display: flex;
  width: 100%;
  align-items: center;
}

.tbldd {
  min-height: 24px;
  display: flex;
  align-items: center;
}

.tblimg {
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.tbi {
  width: 24px;
  height: 24px;
}

.tblim {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  padding-top: 0%;
  margin-right: 8px;
}

.tbltxtn {
  color: RGB(39, 117, 219);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
}

.tbdy tr td {
  font-size: 16px;
  vertical-align: middle;
}

.tblsub {
  font-size: 12px;
  color: skyblue;
  text-align: left;
}

.tblnum {
  color: rgb(189, 194, 196);
  display: flex;
  align-items: center;
}

.tblnump {
  display: flex;
  align-items: center;
  color: RGB(39, 117, 219);
}

.ktblnumper {
  min-width: 60px;
  text-align: left;
}

.tblsub {
  font-size: 12px;
  color: skyblue;
  text-align: left;
}

.tblx {
  display: flex;
  align-items: center;
}

.tblxx {
  color: RGB(39, 117, 219);
  width: 36px;
  text-align: right;
  margin-right: 14px;
}

.tblxxx {
  display: inline-block;
}

.tblsvg {
  align-self: center;
  fill: skyblue;
  flex-shrink: 0;
}

.tblssvvg {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  height: 20px;
}

.tblssvg {
  align-self: center;
  fill: rgb(31, 199, 212);
  flex-shrink: 0;
}

.tblcd {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 8px;
  color: rgb(31, 199, 212);
}

.liq,
.mult {
  display: block;
}

@media screen and (min-width: 1080px) {
  .tbldata {
    padding-right: 32px;
  }
}

@media screen and (min-width: 968px) {
  .tblxx {
    text-align: left;
    margin-right: 0px;
  }
}

@media screen and (min-width: 576px) {
  .tblimg {
    padding-left: 32px;
  }

  .tbi {
    width: 40px;
    height: 40px;
  }

  .tblcd {
    padding-right: 0px;
  }
}

@media screen and (max-width: 480px) {
  .liq,
  .mult {
    display: none;
  }

  .tblimg {
    padding-left: 8px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.edit-profile {
  width: 340px;
  background: #fff;
  box-shadow: 0 0 1px rgb(20 21 26 / 10%), 0 3px 6px rgb(71 77 87 / 4%),
    0 1px 2px rgb(20 21 26 / 4%);
  border-radius: 34px;
  margin: 25px auto;
  padding: 20px 0px;
}

.faq {
  width: 100%;
  box-shadow: rgb(0 0 0 / 40%) 0px 4px 20px;
  border: 1px solid #74b9ff;
  border-radius: 10px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Theme */
:root {
  --theme-background: #1d1b1b !important;
}

body.light-mode {
  background-color: #1d1b1b !important;
  color: #333;
  transition: background-color 0.3s ease;
}

body.dark-mode {
  background-color: #232931;
  color: #dfdfdf;
}
body.dark-mode .nav {
  background-color: #1e272e;
  border-bottom: 1px solid #232931;
}
body.dark-mode .hm-txt {
  color: #fff;
}
body.dark-mode .acc-d {
  background-color: #232931;
  color: #fff;
}
body.dark-mode .home-subhead {
  color: #fff;
}
body.dark-mode .home-create {
  background-color: #1e272e;
}
body.dark-mode .image-fusi {
  background-color: #1e272e;
}
body.dark-mode .footer {
  background-color: #1e272e !important;
  border-top: 1px solid #1e272e;
}
body.dark-mode .drp {
  background-color: #1e272e;
  border-color: #1e272e;
}
body.dark-mode .line {
  background: #232931;
}
body.dark-mode .help-btn {
  color: #fff;
}
body.dark-mode .h-txt {
  color: #fff;
}
body.dark-mode .filter_btn {
  background: #232931;
  color: #fff;
}
body.dark-mode .btn-css {
  box-shadow: none;
}
body.dark-mode .modal-content {
  background: #232931;
}
body.dark-mode .close {
  color: #fff;
}
body.dark-mode .btm-nav {
  background: #1e272e;
  border-color: #1e272e;
}
body.dark-mode .nft-create-card {
  background: #485460;
}
body.dark-mode .nft-img-upload-cover {
  background: #485460;
}
body.dark-mode .input_1 {
  color: #fff;
}
body.dark-mode .text-dark2 {
  color: #fff;
}
body.dark-mode .ant-steps-item-title,
body.dark-mode .ant-steps-item-description {
  color: #fff !important;
}

/* Dark Mode Toggle */

body.dark-mode .dark-mode-toggle > button {
  color: #999;
}
body.dark-mode .dark-mode-toggle > button:last-child {
  color: #4c89dd;
}

.dark-mode-toggle {
  display: flex;
  margin: 0 auto;
}
.dark-mode-toggle > button {
  font-size: 1.2em;
  background: none;
  border: none;
  color: #ffe600;
  cursor: pointer;
  transition: color 0.3s ease;
}
.dark-mode-toggle > button:last-child {
  color: #666;
}
.dark-mode-toggle > button:focus {
  outline: none;
}

.toggle-control {
  position: relative;
  padding: 0 4px;
  display: flex;
  align-items: center;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.dropdown-menu.show {
  z-index: 1;
}
/**Selection */
::selection {
  color: white !important;
  background: #259be45b !important;
}
/* Scrollbar CSS */

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #4c89dd;
  outline: 1px solid #20262d;
  border-radius: 25px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftDiv {
  flex: 60% 1;
}
.rightDiv {
  flex: 40% 1;
}

.rightDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.imgDiv {
  position: relative;
}

.imgDiv img {
  display: block;
  margin: 0 auto;
  width: 350px;
  height: 320px;
}

.imgDiv img {
  box-shadow: -6px 6px 0px 0px #d9d9d9;
  border-radius: 15px;
}

.imgDivCard {
  background: #d9d9d9;
  border-radius: 36px;
  display: flex;
  width: 100%;
  height: 120px;
}

.imgDivCard img {
  border-radius: 36px;
}

.cardMidText {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}

.gridImg {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.gridImg img {
  width: 150px;
  height: 150px;
}

.imgDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0px 0px 18px 18px;
  background: rgba(27, 28, 29, 0.75);
  padding: 8px;
  position: absolute;
  width: 100%;
  bottom: 0px;
}
.imgDetail p {
  color: #fff;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mintNewBtn {
  border-radius: 15px;
  background: #3b9bdf;
  border: none;
  width: 100%;
  padding: 4px;
  text-transform: uppercase;
  color: #fff;
  box-shadow: -6px 6px 0px 0px rgba(255, 255, 255, 1);
}

.belowBtn {
  border-radius: 15px;
  background: #ff1d25;
  border: none;
  width: 100%;
  padding: 4px;
  text-transform: uppercase;
  color: #fff;
  box-shadow: -6px 6px 0px 0px rgba(255, 255, 255, 1);
}

.homeContainer {
  padding: 100px;
}

.leftDiv {
  text-align: left;
}

.leftDiv h1 {
  color: #fff;
  font-size: 78px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cardNew {
  border-radius: 36px;
  background: conic-gradient(
    from 215deg at 50% 50%,
    #ff1d25 0deg,
    #3b9bdf 360deg
  );
  width: 250px;
  height: 250px;
}

/* NavBar CSS */

.nav {
  display: flex;
  flex: 1 1;
  justify-content: center;
  width: 100%;
  height: 60px;
  z-index: 101;
  /* border-bottom: 1px solid #e8ecfd; */
  background-color: #282626;
}

.nav-1 {
  width: 100%;
}

.nav-2 {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  overflow-y: auto;
  min-height: 100%;
}

.nav-3 {
  width: 100%;
  height: 60px;
  overflow: visible;
  min-height: auto;
  position: relative;
}

.head-left {
  display: flex;
  flex-shrink: 1;
  flex-basis: 0;
  flex-grow: 1;
  padding-left: 24px;
}

.head-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1;
  flex-grow: 1;
  padding-left: 24px;
}

.head-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: rgb(14, 16, 60);
  text-decoration: none;
  padding: 8px;
  margin-left: -8px;
  margin-bottom: -4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

.nav-right {
  position: relative;
  display: flex;
  flex-basis: 1200px;
  max-width: 1200px;
  flex: 1 1;
  flex-grow: 1000;
}

.home-nav {
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  flex-basis: 760px;
  padding-right: 80px;
  flex-direction: column;
}

.nav-h1 {
  position: relative;
  height: 44px;
  width: 100%;
  z-index: 0;
}

.nav-sr {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.nav-ser {
  position: relative;
  box-sizing: border-box;
  border-radius: 9px;
  transition: all 0.15s ease-in-out;
  background-color: #ffffff;
  box-sizing: border-box;
  z-index: 1;
}

.nav-sear {
  position: relative;
  transition: border-color 0.15s ease-in-out;
  background-color: #ffffff;
  border-radius: 9px;
  border: 1px solid #e8ecfd;
  overflow: hidden;
}

.nav-sear:focus-within {
  border: 1px solid #0898ec;
}

.nav-ser:focus-within {
  background-color: #e8ecfd;
}

.nav-rc {
  display: none;
}

.nav-rr {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1;
  flex-basis: 460px;
  max-width: 460px;
  padding-right: 24px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #20262d !important;
  border-bottom: 1px solid #e8ecfd;
}

.right-cnt {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1;
  flex-basis: 460px;
  max-width: 460px;
  padding-right: 24px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.rr-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-items: center;
  z-index: 1050;
}

.nav-item {
  margin-right: 24px;
  height: 100%;
  display: flex;
}

.nav-item a + a {
  margin-left: 20px;
  text-decoration: none !important;
}

.home-btn {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: rgb(39, 117, 219);
  text-decoration: none !important;
  /* padding: 8px; */
  margin-left: -8px;
  margin-bottom: -4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.setting-btn {
  /* margin-top: 5px; */
  cursor: pointer;
  margin-left: 15px;
}

.toggle-btn {
  margin-top: 5px;
  cursor: pointer;
  margin-left: 15px;
}

.hm {
  height: 100%;
  position: relative;
}

.hm_active {
  font-weight: bold;
  border-bottom: 2px solid rgb(39, 117, 219);
}

.hm-ex {
  height: 100%;
  position: relative;
}

.btn-cc {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  border: 1px solid rgba(217, 217, 217, 0.06);
  border-radius: 12px;
  background: linear-gradient(
    97.28deg,
    rgb(39, 117, 219) 2.08%,
    rgb(94, 146, 223) 106.51%
  );
  box-shadow: rgb(0 162 250 / 70%) 0px 4px 20px;
  height: 44px;
  padding: 3px 10px 0px;
  display: flex;
  color: rgb(255, 255, 255);
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: rgb(255 255 255 / 25%) 0px 4px 9px inset;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}

.btn-cc:hover {
  color: white;
  text-decoration: none;
}

.cr-btn {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  border: 1px solid rgb(39, 117, 219);
  border-radius: 12px;
  /* background: linear-gradient(
    97.28deg,
    rgb(39, 117, 219) 2.08%,
    rgb(94, 146, 223) 106.51%
  ); */
  box-shadow: rgb(0 162 250 / 70%) 0px 4px 20px;
  height: 44px;
  padding: 3px 22px 0px;
  display: flex;
  color: rgb(255, 255, 255);
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: rgb(255 255 255 / 25%) 0px 4px 9px inset;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
  margin-right: 10px;
}

.btn-cr:hover {
  /* color: white; */
  text-decoration: none;
}

.hm-txt {
  padding: 3px 0px 0px 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: #fff;
  font-family: "Roboto", sans-serif;
}

/* Bottom Nav CSS */

.btm-nav {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #20262d;
  border-top: 1px solid rgba(217, 217, 217, 0.06);
  display: none;
  z-index: 1000;
}

.btm-btns {
  height: 60px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  display: grid;
}
.btm-btns-3 {
  height: 60px;
  grid-template-columns: 1fr 1fr 2fr;
  display: grid;
}

.nav-btn {
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-btn {
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.l-btn {
  height: 44px;
  box-shadow: rgb(255 255 255 / 25%) 0px 4px 9px inset;
  font-size: 16px;
  margin-right: 20px;
}

.lo-btn {
  width: 100%;
  background: rgb(39, 117, 219);
  border-radius: 13px;
  outline: none;
  font-weight: bold;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  line-height: 19px;
  text-align: center;
  color: rgb(255, 255, 255);
  transition: all 0.15s ease-in 0s;
  will-change: transform, background-color, box-shadow;
  cursor: pointer;
}

.log-btn {
  border: none;
  margin: 0px;
  padding: 0px;
  overflow: visible;
  -webkit-font-feature-settings: inherit;
          font-feature-settings: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  -webkit-appearance: none;
          appearance: none;
}

/* Create NFTs */

.crnft {
  padding: 25px;
}

.crnftp {
  margin: 0px 350px 50px !important;
  text-align: center;
  font-size: 18px;
}

.crnft-card {
  width: 100%;
  box-shadow: rgb(0 0 0 / 40%) 0px 4px 20px;
  border-radius: 10px;
  /* border: 1px solid #74b9ff; */
  padding: 25px;
}

.crnft-image {
  display: block;
  margin: auto;
}

.crnft-h1 {
  margin-bottom: 30px;
  font-size: 60px;
  text-align: center;
}

/* Search CSS */

.boxContainer {
  /* margin: auto; */
  position: relative;
  /* width: 300px; */
  /* height: 40px; */
  border: 2px solid #3498db;
  border-radius: 12px;
  /* padding: 10px; */
  margin-bottom: 10px;
  /* display: flex; */
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 375px;
}

.elementsContainer {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.search-bar-dropdown {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.search {
  border: none;
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 25px;
  font-size: 18px;
  color: #424242;
  font-weight: 500;
  margin-left: 20px;
  /* border: 1px solid #0898ec; */
  margin-right: 40px;
}

.search:focus {
  outline: none;
}

.icon {
  float: right;
  font-size: 25;
  color: #0898ec;
  cursor: pointer;
  margin-left: 30px;
}

.list-group-item {
  margin-left: 20px;
  /* border: none !important; */
  padding: 0 -10px !important;
  float: inherit;
  border-bottom: 0.75px solid #6fa7d8 !important;
}

/* Navigation CSS */
.nav_bg,
.navbar {
  background-color: white !important;
}

.navbar a {
  font-size: 18px;
  color: #273c75 !important;
  text-transform: capitalize;
}

.menu_active {
  font-weight: bold;
  border-bottom: 1px solid #273c75;
}

.nav-login {
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  margin-left: 25px;
  text-decoration: none !important;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 2px;
  color: #5a84a2;
  font-size: 18px;
  border-radius: 40px;
  border: 1px solid #273c75;
  /* box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
    -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.15); */
}
.btn-active {
  font-weight: bold;
  color: #273c75;
}

/* DropDown CSS */
.drp {
  position: absolute;
  display: flex;
  width: 170px;
  z-index: 0;
  top: 20px;
  right: -10px;
  will-change: opacity, transform, visibility;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate(-20px);
          transform: translate(-20px);
  box-shadow: rgba(0, 0, 0, 0.05) 20px 20px 55px,
    rgba(0, 0, 0, 0.05) 2px 4px 6px;
  pointer-events: inherit;
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(232, 236, 253);
  border-image: initial;
  border-radius: 12px;
  transition: opacity 0.25s ease-in-out 0s, visibility, -webkit-transform;
  transition: opacity 0.25s ease-in-out 0s, transform, visibility;
  transition: opacity 0.25s ease-in-out 0s, transform, visibility, -webkit-transform;
}

.drp-1 {
  width: 100%;
  padding: 24px 0px 8px;
}

.drp-h {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */
  padding: 0px 15px;
}

.drp-t {
  color: rgb(39, 117, 219);
  font-weight: 600;
}

.drp-tx {
  font-size: 15px;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  padding: 5px 0px;
}

.drp-ac {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: rgb(39, 117, 219);
  text-decoration: none;
  font-family: "Roboto", sans-serif;
}

.drp-btn {
  align-items: center;
  background-color: rgb(255, 255, 255);
  border: 1px solid RGB(61, 129, 221);
  color: RGB(61, 129, 221);
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  padding: 6px 15px;
  text-align: center;
  -webkit-user-select: none;
          user-select: none;
  max-height: 100%;
}

.token {
  padding: 0px;
  cursor: pointer;
}

.token-d {
  padding-top: 18px;
}

.token-det {
  padding: 0px 15px;
}

.token-deta {
  height: 100px;
  background: rgb(247, 247, 255);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.des-txt {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: rgb(0, 0, 0);
  font-family: "Roboto", sans-serif;
}

.line {
  margin: 10px 0px 5px;
  width: 100%;
  height: 1px;
  background: rgb(232, 236, 253);
}

.h-btm {
  padding: 0px;
  cursor: pointer;
}

.help-btn {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: rgb(0, 0, 0);
  text-decoration: none !important;
  display: flex;
  /* align-items: center; */
  padding: 5px 15px;
  /* transition: background 150ms ease-out 0s; */
}

.h-cntn {
  margin-right: 16px;
  height: 16px;
  width: 16px;
}

.h-txt {
  font-size: 16px;
  font-weight: 500;
  padding-top: 3px;
  color: rgb(0, 0, 0);
  font-family: "Roboto", sans-serif;
}

/* Home CSS */

.container {
  /* width: calc(100vw - 32px);
  max-width: 375px !important; */
  margin-top: 25px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column {
  flex: 50% 1;
  /* width: calc(100vw - 32px); */
  max-width: 420px !important;
  max-height: 480px !important;
  margin: 0px 50px 25px;
}

.card {
  /*background: linear-gradient(
    97.28deg,
    rgb(39, 117, 219) 2.08%,
    rgb(94, 146, 223) 106.51%
  );
  /*border-radius: 8px !important;*/
  max-height: 380px;
  /*box-shadow: rgb(255 0 0 / 20%) 1px 4px 12px;*/
  border: none !important;
  background-color: transparent !important;
}

.card-parts {
  /*
  background: linear-gradient(
    162.92deg,
    rgb(245 9 9) 12.36%,
    rgb(24 170 204 / 76%) 94.75%
  );
  /*linear-gradient(
    162.92deg,
    rgb(9 245 212) 12.36%,
    rgb(24 170 204 / 76%) 94.75%
  );
  linear-gradient(
    162.92deg,
    rgb(245 9 9) 12.36%,
    rgb(24 170 204 / 76%) 94.75%
  );
  linear-gradient(
    162.92deg,
    rgb(9 158 245) 12.36%,
    rgb(0, 0, 0) 94.75%
  );
  background:linear-gradient(
    162.92deg,
    rgb(43 69 84) 12.36%,
    rgb(0, 0, 0) 94.75%
  );*/
  box-shadow: rgb(0 0 0 / 40%) 0px 4px 20px;
  /*border-radius: 8px;*/
  color: white;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  cursor: default;
  /*padding: 2.5px;*/
  z-index: 1;
  -webkit-transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
          transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
}

.card-top-head {
  display: none;
  top: 0;
  position: absolute;
  font-weight: 300;
  font-size: 24px;
  line-height: 126.7%;
  width: 100%;
  margin: 0px;
}

.card-top-below {
  display: none;
  position: absolute;
  top: 0;
  float: right;
  color: rgb(22, 23, 26);
  text-shadow: 0 0.75px pink;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 156.7%;
  width: 100%;
  margin: 0px;
  -webkit-font-feature-settings: "tnum", "onum";
          font-feature-settings: "tnum", "onum";
}

p {
  display: block;
  -webkit-margin-before: 1em;
          margin-block-start: 1em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}

.image-f {
  /*width: 50%;
  height: 40%;*/
  background: #0d131885;
  /*max-height: 300px;*/
  display: flex;
  align-items: center;
  box-shadow: rgb(0 0 0 / 5%) 12px 12px 0px;
}

/* img {
  height: 50%;
  width: 50%;
} */

.image-fusi {
  object-fit: contain;
  display: block;
  margin: auto;
  min-width: -webkit-fill-available;
  min-width: -moz-available;
  height: 375px !important;
  background: #f0f8ffde;
  /*min-height: 420px !important;
  max-height: 480px !important;

  background: #17b8b1;
  box-sizing: border-box;
  border-radius: 4px;
  */
}

.bottom-desc {
  position: absolute;
  bottom: 0;
  background: rgb(4 159 232 / 50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 1rem;
}

.price {
  font-weight: 600;
  font-size: 18px;
  margin: 0px 0px 0.5rem;
}

.available {
  color: rgb(174, 174, 174);
  font-weight: 400;
  margin-left: -225px;
  font-size: 12px;
}

.right-corner {
  color: rgb(174, 174, 174);
  font-weight: 400;
  margin: 2px;
  font-size: 12px;
  float: right;
}

.left-corner {
  color: rgb(174, 174, 174);
  font-weight: 400;
  margin: 1px 0px 0px;
  font-size: 12px;
  float: left;
}

.description {
  color: rgb(0, 0, 0);
  font-weight: 500;

  font-size: 14px;
  padding: 5px 20px 20px;
  border-radius: 8px;
  /*background-color: rgb(192 220 245); /
  background-color:rgb(46 217 255); 
  background-color:rgb(130 216 255);*/
  /*background-color:rgb(244 255 201) !important;
  background-color: rgb(165 214 255);*/
  background: linear-gradient(183deg, #e0eeff, #68baff);
  padding-top: 25px;
}

.desc-txts {
  margin: -16px;
  background-color: #20262d;
  border-radius: 8px;
  padding: 5px 0px 5px 5px;
  max-height: 170px !important;
}

.card-fusii {
  background: rgb(0 0 0 / 3%) !important;
}

.description a {
  color: rgb(254, 109, 222);
  text-decoration: none;
}

.buy-btn {
  margin: 0.5rem 0rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  color: rgb(0, 0, 0);
}

.buy-btn-now {
  width: 100%;
  text-align: center;
}

.btn-css {
  margin: 2px;
  border-radius: 8px;
  box-sizing: border-box;
  height: 48px;
  /* width: 100%; */
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  border: 0px solid;
  cursor: pointer;
  opacity: 1;
  pointer-events: auto;
  background: linear-gradient(
    97.28deg,
    rgb(39, 117, 219) 2.08%,
    rgb(94, 146, 223) 106.51%
  );
  box-shadow: rgb(0 162 250 / 70%) 0px 4px 20px;
  color: rgb(255, 255, 255);
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: -webkit-transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}

.btn-txt {
  text-align: center;
}

/* Modal CSS */
.modal-content {
  width: 340px !important;
  background: #fff;
  box-shadow: 0 0 1px rgb(20 21 26 / 10%), 0 3px 6px rgb(71 77 87 / 4%),
    0 1px 2px rgb(20 21 26 / 4%);
  border-radius: 34px !important;
  margin: 25px auto;
  margin-top: 25px;
  margin-right: auto;
  margin-bottom: 25px;
  margin-left: auto;
  padding-bottom: 10px;
}
.modal_body {
  border-bottom: none !important;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.form-control {
  border: none !important;
}

.button {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  background: #0984e3;
  border-radius: 100px;
  border: 3px solid #0984e3;
  border-width: 3px 3px 3px 3px;
  padding: 5px 60px 5px 20px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}
.button-left {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  background: #d63031;
  border-radius: 100px;
  border: 3px solid #d63031;
  border-width: 3px 3px 3px 3px;
  padding: 5px 5px 5px 70px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}
.button-left > div {
  color: #999999;
  font-size: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: initial;
  font-style: normal;
  text-align: center;
  margin: 0px 0px 0px 0px;
}
.button > div {
  color: #999999;
  font-size: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: initial;
  font-style: normal;
  text-align: center;
  margin: 0px 0px 0px 0px;
}
.button > i {
  font-size: 1em;
  background: #74b9ff;
  border-radius: 100px;
  border: 0px solid transparent;
  border-width: 0px 13px 13px 0px;
  padding: 5px 6px 5px 16px;
  margin: 2px 2px 2px 2px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 48px;
  height: 28px;
}
.button-left > i {
  font-size: 1em;
  background: #ff7675;
  border-radius: 100px;
  border: 0px solid transparent;
  border-width: 0px 13px 13px 0px;
  padding: 5px 6px 5px 16px;
  margin: 2px 2px 2px 2px;
  position: absolute;
  top: 0px;
  left: 10px;
  bottom: 0px;
  right: 0px;
  width: 48px;
  height: 28px;
}
.button > .ld {
  font-size: initial;
}

/* Account Icon CSS */

.rr-icon {
  display: flex;
  align-items: center;
  border-left: 1px solid black;
  padding-left: 5px;
}

.btn-icon {
  z-index: 1;
  cursor: pointer;
  outline: none;
  padding: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: black;
  border-radius: 999px;
}

.btn-icon1 {
  border: none;
  margin: 0px;
  padding: 0px;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font-style: inherit;
  -webkit-font-feature-settings: inherit;
          font-feature-settings: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  -webkit-appearance: none;
          appearance: none;
}

.rr-icon button + button {
  margin-left: 18px;
}

.acc-d {
  display: flex;
  align-items: center;
}

.acc-d > :not(:first-child) {
  margin-left: 8px;
}

.network-name {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  width: 100%;
  background-color: #4c89dd;
  color: #fff;
  font-weight: 500;
  border-radius: 12px;
  padding: 8px 12px;
}

.amount {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #a5d6ff;
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
}

.css-a {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  padding-left: 0.75rem;
  padding-right: 0.5rem;
  font-weight: 500;
}

.acc-d {
  text-align: center;
  outline: none;
  text-decoration: none;
  justify-content: center;
  position: relative;
  z-index: 1;
  will-change: transform;
  transition: -webkit-transform 450ms ease 0s;
  transition: transform 450ms ease 0s;
  transition: transform 450ms ease 0s, -webkit-transform 450ms ease 0s;
  -webkit-transform: perspective(1px) translateZ(0px);
          transform: perspective(1px) translateZ(0px);
  font-size: 16px;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  background-color: #20262d;
  /* border: 1px solid rgb(237, 238, 242); */
  color: rgb(0, 0, 0);
  font-weight: 500;
}

.acc-number {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 0.5rem;
  font-size: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-weight: 500;
  padding: 0.5rem;
  position: relative;
  top: -2px;
}

.acc-d > * {
  -webkit-user-select: none;
          user-select: none;
}

.acc-icon {
  height: 1rem;
  width: 1rem;
  border-radius: 1.125rem;
  background-color: rgb(136, 141, 155);
}

/* Explore Style */

h1 {
  font-size: 3rem;
}

.ex_app {
  padding: 2rem 5rem;
}

.ex_title {
  padding-bottom: 2rem;
  text-align: center;
}

h4 {
  font-size: 2rem;
  font-weight: 200;
  margin: 1rem 0;
}

h4:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #0984e3;
}

.p_desc {
  background: #121fcf;
  background: linear-gradient(70deg, #e91e63 0%, #03a9f4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  font-weight: bold;
}

.item_e {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2.5rem;
}

.item_con {
  width: 100%;
  /*padding: 0.6rem;*/
  box-shadow: rgb(0 0 0 / 40%) 0px 4px 20px;
  /* background-color: rgb(192 220 245); */
  border-radius: 5%;
  border: 1px solid #74b9ff;
}

.item_container {
  overflow: hidden;
  padding: 0.6rem;
}

.item_container > img {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  /* transition: all 0.3s ease-in-out; */
}

/* .item_container > img:hover {
  transform: scale(1.1);
} */

.ex_s {
  padding: 1rem;
  color: #0984e3;
}

.filter_button {
  width: 70%;
  margin: 2rem auto;
  text-align: center;
}

.filter_btn {
  padding: 0.5rem 2rem;
  border: 1px solid #0984e3;
  font-weight: 600;
  color: #000;
  background-color: #20262d;
  transition: all 0.4s cubic-bezier(1, -0.16, 0.6, 1.35);
  border-radius: 5px;
}

.filter_btn:not(:last-child) {
  margin-right: 1rem;
}

.filter_btn:hover {
  background-color: #0984e3;
  cursor: pointer;
  color: #fff;
}

.filter_btn:active,
.filter_btn:focus {
  background-color: #0984e3;
}

p {
  margin-bottom: -5px !important;
}

.creator {
  display: flex;
}

.crby {
  font-size: 14px;
  color: gray;
  margin-right: 5px;
  margin-top: 7px;
}

.crName {
  font-size: 20px;
  color: #0984e3;
}

.videoCss {
  width: 100%;
  height: 240px;
  max-height: 360px !important;
}

/* Slider CSS */

.carousel-indicators {
  display: none;
}

.carousel-item {
  padding: 25px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-item.active {
  opacity: 1;
}

.carousel {
  height: 400px;
  width: 420px;
}

.image {
  width: 900px;
  height: 450px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: -50px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: -50px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

/* .slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
} */

/* Sort Btn CSS */

.sort {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;
  margin: 15px;
}

.sort_s > .sort_data {
  font-size: 1.5rem;
}

.search_b > .sort_data {
  font-size: 1.5rem;
}

.search_b {
  margin-left: 60%;
}

.sort_ds {
  color: #121fcf;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.sort_da {
  cursor: pointer;
  width: 168px;
  position: relative;
  background: #20262d;
  border-radius: 16px;
  height: 40px;
  min-width: 136px;
}

.sort_dab {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  border: 1px solid #0898ec;
  border-radius: 16px;
  background: #20262d;
  transition: border-radius 0.15s ease 0s;
}

.sort_da svg {
  position: absolute;
  right: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.lkvAzg {
  fill: #121fcf;
  flex-shrink: 0;
}

.search_bb {
  position: relative;
}

.huvkpK {
  border-radius: 16px;
  margin-left: auto;
}

.dGmcca {
  background-color: #20262d;
  border: 0px;
  border-radius: 16px;
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  border: 1px solid #0898ec;
  color: rgb(69, 42, 122);
  display: block;
  font-size: 16px;
  height: 40px;
  outline: 0px;
  padding: 0px 16px;
  width: 100%;
}

/* Profile Page CSS */

.pp {
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.pp2 {
  height: 260px;
  background-color: rgba(4, 4, 5, 0.03);
  position: relative;
}

.pp1 {
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.pph {
  position: absolute;
  bottom: -30px;
  left: 50%;
  overflow: hidden;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 10;
}

.ppi {
  border: 4px solid #20262d;
  border-radius: 100%;
  position: relative;
}

.ppi1 {
  width: 120px;
  height: 120px;
  position: relative;
}

.ppimg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  border-radius: 120px;
  width: 120px;
  height: 120px;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
}

.pnt {
  margin: 52px 0px 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.pntx {
  /* margin: 52px 0px 0px; */
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.pntxt {
  margin: 0px;
  padding: 0px 16px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.pht {
  margin: 0px;
  padding: 0px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  align-items: center;
  justify-content: center;
}

.pst {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
}

.pstx {
  text-decoration: none;
  color: inherit;
  font-size: 28px;
  line-height: 30.8px;
  font-family: inherit;
  font-weight: 700;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.pstxt {
  text-decoration: none;
  font-size: 26px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.rms {
  margin: 0px 0px 0px 8px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  display: inline;
}

.cpb {
  width: auto;
  color: inherit;
  padding: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-style: inherit;
  -webkit-font-feature-settings: inherit;
          font-feature-settings: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  overflow: visible;
  outline: none;
}

.cpbt {
  text-decoration: none;
  color: rgba(4, 4, 5, 0.4);
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.btne {
  margin: 8px 0px 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.prbtn {
  margin: 20px 0px 0px;
  padding: 0px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  flex-direction: row;
  align-items: center;
}

.fbtn {
  background-color: #e4f0ff;
  border-color: rgb(0, 102, 255);
  color: #000;
}

.flbtn {
  height: 40px;
  /* background-color: rgba(4, 4, 5, 0.05); */
  padding-left: 22px;
  padding-right: 22px;
  min-width: auto;
  border: 1px solid transparent;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  font-size: 14px;
  /* color: rgb(4, 4, 5); */
  font-weight: 900;
  font-family: inherit;
  transition: all 0.12s ease-in-out 0s;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-user-select: none;
          user-select: none;
}

.ppb2 {
  margin: 0px 0px 0px 8px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.pbt2 {
  display: inline-block;
}

.pbt3 {
  border-color: rgba(4, 4, 5, 0.1);
  background: transparent;
}

.pbt4 {
  height: 40px;
  width: 40px;
  border: 1px solid transparent;
  align-items: center;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  transition: all 0.12s ease-in-out 0s;
  border-radius: 40px;
  background-color: rgba(0, 102, 255, 0.05);
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

/* Report Option CSS */

.tippy-box {
  position: relative;
  background-color: rgb(255, 255, 255);
  color: rgb(4, 4, 5);
  border-radius: 5px;
  border: 0px solid rgba(255, 255, 255, 0.1);
  font-weight: normal;
  outline: 0px;
  box-shadow: rgb(4 4 5 / 20%) 0px 7px 36px -8px;
  transition: all 0.12s ease-in-out 0s;
}

.bYZZJZ {
  padding: 0px;
  font-size: 15px;
  font-weight: 500;
}

.bbWfCl {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px 0px;
}

.evgNzS {
  display: flex;
  flex-flow: column;
}

.dIvkOO {
  cursor: pointer;
  color: rgb(4, 4, 5);
  transition: all 0.12s ease-in-out 0s;
  text-align: left;
  height: 38px;
  padding: 0px 12px;
  margin: 0px 12px;
  border-radius: 6px;
}

.dIvkOO:hover {
  background-color: #f7f7f7;
}

/* Share CSS */
.bYZZJZ {
  padding: 0px;
  font-size: 15px;
  font-weight: 500;
}

.bbWfCl {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px 0px;
}

.gXpHxv {
  margin: 0px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  padding: 16px;
  justify-content: center;
  align-items: center;
}

.bRtXqT {
  text-decoration: none;
  color: inherit;
  font-size: 18px;
  line-height: 24.84px;
  font-family: inherit;
  font-weight: 900;
  text-align: center;
}

.eGqSvC {
  margin: 16px 0px 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  width: 290px;
}

.jBEPVW {
  flex-flow: row wrap !important;
  justify-content: space-around;
}

.jbxvPE {
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.jBEPVW > * {
  width: 22%;
}

.hnlxBI {
  margin: 0px;
  padding: 0px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  justify-content: center;
  align-items: center;
}

.dQqrst {
  border-color: rgba(4, 4, 5, 0.1);
  background: transparent;
}

.KGdWz {
  height: 40px;
  width: 40px;
  border: 1px solid transparent;
  align-items: center;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  transition: all 0.12s ease-in-out 0s;
  border-radius: 40px;
  background-color: rgba(0, 102, 255, 0.05);
  -webkit-transform-origin: center center;
          transform-origin: center center;
}

.oMHNp {
  margin: 8px 0px 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

/* Navtabs CSS */

.nt {
  margin: 32px 0px 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: -1 !important;
}

.nt1 {
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: -1;
}

.nt2 {
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: -1;
  position: relative;
}

.dlSpeG {
  margin: 0px;
  padding: 0px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  flex-flow: row nowrap;
  align-items: center;
}

.iQUCaz > :not(:last-child) {
  margin-right: 16px;
}

.dJLimD {
  height: 30px;
  color: rgba(4, 4, 5, 0.4);
  justify-content: center;
  position: relative;
  padding-bottom: 2px;
}

.erMVgR {
  width: auto;
  color: inherit;
  padding: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-style: inherit;
  -webkit-font-feature-settings: inherit;
          font-feature-settings: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  overflow: visible;
  outline: none;
}

.eSHvkk {
  white-space: nowrap;
}

.eqDhyJ {
  text-decoration: none;
  color: inherit;
  transition: all 0.12s ease-in-out 0s;
  font-size: 18px;
  line-height: 20.7px;
  font-family: inherit;
  font-weight: 900;
}

.jyJZLg {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(4, 4, 5, 0);
  height: 2px;
  width: 100%;
}

.bvKcKO {
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  border-bottom: 2px solid #121fcf;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  transition: all 0.12s ease-in-out 0s;
}

/* Explore Card Head CSS */

.ch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.chd {
  display: flex;
  flex-direction: row;
  /* z-index: -1; */
}

.eesVrg {
  text-decoration: none;
  color: rgb(0, 102, 255);
}

.fnyloc {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.ABQJT {
  width: 26px;
  height: 26px;
  position: relative;
}

.gtRctY {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  border-radius: 26px;
  width: 26px;
  height: 26px;
  overflow: hidden;
  /* background-color: rgb(255, 255, 255); */
}

.chd > :not(:first-child) {
  margin-left: -10px;
}

.fCQiqA {
  display: inline-block;
}

/* CardPOptn CSS */

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
          appearance: button;
}

.eHfBxm {
  cursor: pointer;
  color: rgb(4, 4, 5);
  transition: all 0.12s ease-in-out 0s;
  text-align: left;
  min-height: 32px;
  padding: 8px 12px;
  margin: 0px 12px;
  border-radius: 6px;
}

.erMVgR {
  width: auto;
  color: inherit;
  padding: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-style: inherit;
  -webkit-font-feature-settings: inherit;
          font-feature-settings: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  overflow: visible;
  outline: none;
}

.eIGEyX {
  margin: 20px 0px 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.fkBquX {
  margin: 0px;
  padding: 0px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.eyxiok {
  text-decoration: none;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: 700;
  color: rgb(4, 4, 5);
  margin-left: 8px;
}

.kmErAh {
  vertical-align: middle;
}

.hSKhZv > :not(:last-child) {
  margin-right: 16px;
}

.ktlGIb {
  transition: all 0.12s ease-in-out 0s;
  color: rgba(4, 4, 5, 0.6);
}

.eesVrg {
  text-decoration: none !important;
  color: rgb(0, 102, 255);
}

.fnyloc {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.chip {
  display: inline-block;
  padding: 0 25px;
  width: 100%;
  height: 35px;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
}

.chip img {
  float: left;
  margin: 5px 10px 0 -25px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.netLogo {
  width: 14px !important;
  height: 12px !important;
  /* padding: 1px; */
  margin-top: -2px;
}

.bscNetLogo {
  width: 16px !important;
  height: 16px !important;
  margin-left: 40px;
  margin-top: 3px;
}

.tgl + .tgl-btn {
  outline: 0;
  /* display: block; */
  width: 2em;
  height: 1.1em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none !important;
          user-select: none !important;
  margin-left: 5px;
  margin-right: 5px;
}
.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 45%;
  height: 100%;
}
.tgl + .tgl-btn:after {
  left: 0;
}
.tgl + .tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  left: 50%;
}

.tgl-ios + .tgl-btn {
  background: #c0dcf5;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  border: 1px solid #518bdf;
}
.tgl-ios + .tgl-btn:after {
  border-radius: 2em;
  background: #518bdf;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}
.tgl-ios + .tgl-btn:hover:after {
  will-change: padding;
}
.tgl-ios + .tgl-btn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
}
.tgl-ios + .tgl-btn:active:after {
  padding-right: 0.8em;
}
.tgl-ios:checked + .tgl-btn {
  background: #c0dcf5;
}
.tgl-ios:checked + .tgl-btn:active {
  box-shadow: none;
}
.tgl-ios:checked + .tgl-btn:active:after {
  margin-left: -0.8em;
}

/* Mint NFT CSS */

.ant-steps-item-wait .ant-steps-item-icon {
  border-color: #4c89dd !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #4c89dd !important;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #4c89dd !important;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: grey !important;
}
.ant-radio-button-wrapper {
  margin: 0 6px !important;
}

.nft-create-card {
  width: 340px;
  background: #fff;
  box-shadow: 0 0 1px rgb(20 21 26 / 10%), 0 3px 6px rgb(71 77 87 / 4%),
    0 1px 2px rgb(20 21 26 / 4%);
  border-radius: 34px;
  margin: 25px auto;
  padding-bottom: 10px;
}

.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum", "tnum", "tnum";
          font-feature-settings: "tnum", "tnum", "tnum";
}

.nft-img-upload-cover,
.nft-img-uploaded {
  border-top-right-radius: 34px;
  border-top-left-radius: 34px;
}

.nft-img-upload-cover {
  height: 236px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.nft-create-card-bottom {
  padding: 8px 18px;
}

.input_1 {
  margin-bottom: 10px;
}

.input_1 Input,
.input_1 TextArea {
  border: 1px solid #8a8a8e;
  border-radius: 10px;
  padding: 10px;
}

.input_2 {
  border: 1px solid #8a8a8e;
  border-radius: 10px;
  padding: 10px;
}

.input_2 span {
  padding-left: 25px;
  font-size: 20px;
}

.nft-card-buy-button {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1773eb;
  border-radius: 17px;
}

.ant-btn > span {
  display: inline-block;
}
.nft-card-buy-button-text {
  text-align: center;
  flex: 0 0 120px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 110%;
  color: #fff;
}

.avatar-uploader > .ant-upload {
  width: 200px;
  height: 200px;
}

/* ------------------ Media Querry -------------------- */
@media (max-width: 1200px) {
  .home-nav {
    padding-right: 40px;
  }

  .crnftp {
    margin: auto !important;
  }

  .item_e {
    grid-template-columns: repeat(3, 1fr);
  }

  .filter_button {
    margin: 1rem auto;
    width: auto;
  }

  .filter_btn:not(:last-child) {
    margin-right: auto;
  }
}

@media screen and (max-width: 1024px) {
  .image {
    width: 500px;
    height: 250px;
    border-radius: 10px;
  }

  .right-arrow {
    right: -10px;
  }

  .left-arrow {
    left: -10px;
  }

  .column {
    flex: 100% 1;
    /* width: calc(100vw - 32px); */
    max-width: 375px !important;
    margin: 25px;
  }

  .homeContainer {
    padding: 25px;
  }
}

@media screen and (max-width: 768px) {
  .filter_btn {
    margin: 1rem;
  }

  .item_e {
    grid-template-columns: repeat(2, 1fr);
  }

  .pntxt {
    padding-left: 28px;
    padding-right: 28px;
    /* padding-bottom: 75px; */
  }

  .home-nav {
    padding-right: 0;
  }

  .nav-sm {
    display: none;
  }

  .nav-ser {
    background-color: #e8ecfd;
  }

  .btm-nav {
    display: block;
  }

  .right-cnt {
    display: none;
  }

  .nav-rc {
    display: block;
    /* padding: 5px; */
  }

  .crnft {
    padding-bottom: 50px;
  }

  .flex {
    flex-wrap: wrap;
    justify-content: center;
  }

  .leftDiv h1 {
    font-size: 70px;
  }
}

@media screen and (max-width: 480px) {
  #root {
    padding-bottom: 50px !important;
  }
  .search-bar-dropdown {
    margin-left: 50px;
  }

  .list-group-item {
    margin-left: -2px;
  }

  .image {
    width: 300px;
    height: 150px;
    border-radius: 10px;
  }

  .right-arrow {
    right: 2px;
    font-size: 1rem;
  }

  .left-arrow {
    left: 2px;
    font-size: 1rem;
  }

  .filter_btn {
    margin: 0.5rem;
  }

  .item_e {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 20%;
  }

  .ex_app {
    padding: 0.5rem 2rem;
  }

  .btm-nav {
    display: block;
  }

  .right-cnt {
    display: none;
  }

  .sort_da {
    min-width: 168px;
  }

  .search_bb {
    width: 140px;
    display: block;
  }

  .search_b {
    margin-left: 16px;
  }

  .sort {
    margin: 0px;
    margin-top: 5px;
  }

  .search_b > .sort_data {
    font-size: 1rem;
  }

  .sort_s > .sort_data {
    font-size: 1rem;
  }

  .sort_s {
    margin-left: -8px;
  }

  .pntxt {
    padding-left: 24px;
    padding-right: 24px;
    /* padding-bottom: 75px; */
  }

  #tippy-5 {
    margin-top: 36% !important;
    -webkit-transform: translate3d(182px, 396px, 0px) !important;
            transform: translate3d(182px, 396px, 0px) !important;
  }

  #tippy-4 {
    margin-top: 36% !important;
    -webkit-transform: translate3d(33px, 396px, 0px) !important;
            transform: translate3d(33px, 396px, 0px) !important;
  }

  /* #tippy-10 {
    margin: 0px;
    transform: translate3d(33px, 396px, 0px);
  } */

  .image-fusi {
    min-height: 320px !important;
    max-height: 380px !important;
  }

  .description {
    margin-top: 0px;
  }

  .search {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .crnft-h1 {
    font-size: 38px;
  }

  .crnftp {
    font-size: 14px;
  }

  .leftDiv h1 {
    font-size: 36px;
  }

  .carousel {
    height: 300px;
    width: 300px;
  }

  .imgDiv img {
    width: 200px;
    height: 200px;
  }

  .gridImg img {
    width: 100px;
    height: 100px;
  }

  .imgDivCard {
    height: 80px;
  }

  .imgDivCard img {
    width: 100px;
    height: 80px;
  }

  .cardMidText {
    margin: 0px;
  }

  .imgDivCard > div > p {
    margin: 8px;
  }
}

